import React from 'react'
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'

import styles from './informationContainer.module.scss'

/* Apollo */
import { Query } from 'react-apollo'
import { GET_LOCAL_NEPTUNE_COMPANY_DETAIL } from '../../../schemas/queries'

const INTERNATIONAL_LABEL_OTHER = 'Unique Enterprise Identifier'

const INTERNATIONAL_LABEL = {
  193: 'UEN',
  167: 'TIN',
  105: '法人番号'
}

const InformationContainer = () => {
  return (
    <Query query={GET_LOCAL_NEPTUNE_COMPANY_DETAIL}>
      {({ data: { getNeptuneCompany }, loading, error }) => {
        const {
          message,
          data: {
            applicantRecruiter = {},
            neptuneCompany = {},
            companyCountryNumber = {}
          }
        } = getNeptuneCompany || {}
        const { avatar = null, firstName, lastName, phoneNum } =
          applicantRecruiter || {}
        const {
          legalName,
          country: { value: countryValue, id: countryId }
        } = neptuneCompany

        const { companyNumber = null } = companyCountryNumber || {}

        if (loading) return <div>Loading...</div>

        if (error) return <div>Error : {message}</div>

        // 在这里可以处理data并返回JSX
        return (
          <div>
            <div>
              <img src={avatar} alt="avatar" className={styles.avatar} />
            </div>

            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup row>
                    <Label md={3} sm={12}>
                      First Name:
                    </Label>
                    <Col md={9} sm={12}>
                      <Input
                        disabled={true}
                        name="firstName"
                        value={firstName}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup row>
                    <Label md={3} sm={12}>
                      Last Name:
                    </Label>
                    <Col md={9} sm={12}>
                      <Input disabled={true} name="lastName" value={lastName} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup row>
                    <Label md={3} sm={12}>
                      Phone Num:
                    </Label>
                    <Col md={9} sm={12}>
                      <Input disabled={true} name="lastName" value={phoneNum} />
                    </Col>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup row>
                    <Label md={3} sm={12}>
                      {INTERNATIONAL_LABEL[countryId] ||
                        INTERNATIONAL_LABEL_OTHER}
                      :
                    </Label>
                    <Col md={9} sm={12}>
                      <Input
                        disabled={true}
                        name="lastName"
                        value={companyNumber}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup row>
                    <Label md={3} sm={12}>
                      Legal Name:
                    </Label>
                    <Col md={9} sm={12}>
                      <Input
                        disabled={true}
                        name="legalName"
                        value={legalName}
                      />
                    </Col>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup row>
                    <Label md={3} sm={12}>
                      Country:
                    </Label>
                    <Col md={9} sm={12}>
                      <Input
                        disabled={true}
                        name="country"
                        value={countryValue}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        )
      }}
    </Query>
  )
}

export default InformationContainer
