import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CONFIG from 'config'

/* Bootstrap */
import { Form, FormGroup, Label, Input, Container, Col, Row } from 'reactstrap'

/* Components */
import SectionWrapper from 'shared/components/SectionWrapper'
import TextEditorField from 'shared/components/TextEditorField'
import MultiSelectDropdown from 'shared/components/MultiSelectDropdown'
import Skills from 'modules/bossjob_users/views/BossJobUserDetail/UserProfile/Skills'

import {
  getSalariesTypeOptions,
  getCurrentShouldShowObject,
  getSalaryOption
} from 'shared/helpers/useSalariesUtil'

/**
 * JobDetailInformationForm
 *
 * @returns {Component}
 *
 */
class JobDetailInformationFormContainer extends Component {
  static propTypes = {
    jobDetail: PropTypes.object,
    workAddresses: PropTypes.array,
    defaultWorkAddress: PropTypes.object,
    recruiterSubscriptionPlanDetail: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    handleTextEditorChange: PropTypes.func.isRequired,
    isInvalid: PropTypes.bool.isRequired,
    updateLocalJobDetailState: PropTypes.func.isRequired,
    handleDynamicInputFieldChange: PropTypes.func.isRequired,
    handleJobBenefitsFieldChange: PropTypes.func.isRequired,
    handleWorkAddressChange: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      options: {},
      locations: [],
      benefits: [],
      jobFunctions: [],
      jobFunctionQuery: '',
      countries: [],
      mainJobFunctions: [],
      currencies: [],
      workArrangements: [],
      isRemote: false,
      currencyId: undefined,
      salaryTypeLists: [],

      refresh: Math.random()
    }
  }

  componentDidMount() {
    this._fetchFieldOptions()
    this.setState({
      isRemote: Boolean(this.props.jobDetail.workArrangementId === 3),
      currencyId: this.props.jobDetail.currencyId
    })
  }

  /**
   *
   * @function renderSalaryOption - render salary options with the corresponding value
   *
   * @param {Number} start
   * @param {Number} end
   * @returns {Component}
   */

  formatSalary = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // TODO 通过薪水间隔计算获得薪水列表
  getSalaryOption = (currentSalaryRange, bound) => {
    let salaryOptions = []
    if (currentSalaryRange) {
      let { from, to, interval, upper_bound_scale } = currentSalaryRange
      if (bound === 'upper') {
        to = to * upper_bound_scale
      }

      for (let salary = from; salary <= to; salary += interval) {
        salaryOptions.push(salary)
      }
    }
    return salaryOptions
  }

  /**
   *
   * @function _fetchFieldOptions - Fetch all the form field options
   */
  _fetchFieldOptions() {
    fetch(`${CONFIG.BOSSJOB_CONFIG_URL}/ph/list?language_code=en`)
      .then(response => response.json())
      .then(data => {
        const {
          main_job_function_lists,
          country_lists,
          currency_lists,
          work_arrangement_lists,
          timezone_lists,
          job_languages,
          job_benefit_lists,
          xp_lvls,
          job_types,
          degrees,
          salary_ranges_lists,
          salary_type_lists,

          hourly_salary_range_lists,
          daily_salary_range_lists
        } = data.data

        let locations = []
        // console.log({ salary_type_lists })
        this.setState({
          mainJobFunctions: main_job_function_lists,
          countries: country_lists,
          currencies: currency_lists,
          workArrangements: work_arrangement_lists,
          timezones: timezone_lists,
          languages: job_languages,
          benefits: job_benefit_lists,
          xpLvls: xp_lvls,
          jobTypes: job_types,
          degrees: degrees,
          salaryRanges: salary_ranges_lists,
          salaryTypeLists: salary_type_lists,
          hourlySalaryRangeLists: hourly_salary_range_lists,
          dailySalaryRangeLists: daily_salary_range_lists
        })
        for (const country of this.state.countries) {
          for (const region of country.region_lists) {
            for (const location of region.location_lists) {
              locations.push({
                country: country,
                location: location,
                regionObj: region
              })
            }
          }
        }
        locations.sort((a, b) => (a.location.key > b.location.key ? 1 : -1))
        this.setState({ locations: locations })
      })
  }

  /**
   *
   * @function _renderJobFunctionTitleField
   * @returns {Component}
   */
  _renderJobFunctionTitleField() {
    const { mainJobFunctions } = this.state
    const { jobFunctionQuery } = this.state
    const {
      jobDetail: { jobTitleId },
      handleChange,
      isInvalid
    } = this.props

    return (
      <FormGroup>
        <Label for="jobTitleId">Job Function Title</Label>
        <Input
          type="text"
          name="jobFunctionQuery"
          id="jobFunctionQuery"
          placeholder="Enter job function title here to filter"
          value={jobFunctionQuery}
          onChange={e => this.setState({ jobFunctionQuery: e.target.value })}
        />
        <Input
          type="select"
          name="jobTitleId"
          id="jobTitleId"
          value={jobTitleId}
          onChange={handleChange}
          disabled={isInvalid}
        >
          {mainJobFunctions &&
            mainJobFunctions.map(mainFunctionObj => {
              const mainFunction = mainFunctionObj.value
              const subFunctionObjs = mainFunctionObj.sub_function_list
              const filteredJobTitles =
                jobFunctionQuery !== ''
                  ? subFunctionObjs.flatMap(subFunctionObj =>
                      subFunctionObj.job_titles.filter(
                        jobTitle =>
                          jobTitle.value
                            .toLowerCase()
                            .includes(jobFunctionQuery.toLowerCase()) ||
                          jobTitle.id == jobTitleId
                      )
                    )
                  : subFunctionObjs.flatMap(
                      subFunctionObj => subFunctionObj.job_titles
                    )

              return subFunctionObjs.map(subFunctionObj => {
                return subFunctionObj.job_titles.map((jobTitle, idx) => {
                  return (
                    <React.Fragment key={`jobFunction_${idx}`}>
                      {idx === 0 &&
                        subFunctionObj.job_titles.some(title =>
                          title.value
                            .toLowerCase()
                            .includes(jobFunctionQuery.toLowerCase())
                        ) && (
                          <option
                            key={`subFunction_${subFunctionObj.value}`}
                            disabled
                          >
                            {mainFunction} - {subFunctionObj.value}
                          </option>
                        )}
                      {filteredJobTitles.includes(jobTitle) ? (
                        <option
                          key={`jobTitleId_${jobTitle.id}`}
                          value={jobTitle.id}
                        >
                          {jobTitle.value}
                        </option>
                      ) : null}
                    </React.Fragment>
                  )
                })
              })
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobTitleField
   * @returns {Component}
   */
  _renderJobTitleField() {
    const {
      jobDetail: { jobTitle },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobTitle">Job Title</Label>
        <Input
          type="text"
          name="jobTitle"
          id="jobTitle"
          value={jobTitle}
          onChange={handleChange}
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobTitleRefField
   * @returns {Component}
   */
  _renderJobTitleRefField() {
    const {
      jobDetail: { jobTitleRef, relatedJobs },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobTitleRef">
          Job Title Reference (
          {relatedJobs.length > 0 ? (
            <span>Related jobs found</span>
          ) : (
            <span style={{ color: 'red' }}>Related jobs not found</span>
          )}
          )
        </Label>
        <Input
          type="text"
          name="jobTitleRef"
          id="jobTitleRef"
          value={jobTitleRef}
          onChange={handleChange}
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobTypeField
   * @returns {Component}
   */
  _renderJobTypeField() {
    const { jobTypes } = this.state
    const {
      jobDetail: { jobTypeKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobTypeKey">Job Type</Label>
        <Input
          type="select"
          name="jobTypeKey"
          id="jobTypeKey"
          value={jobTypeKey}
          onChange={handleChange}
          disabled={isInvalid}
        >
          {jobTypes &&
            jobTypes.map((jobType, index) => {
              return (
                <option key={index} value={jobType.key}>
                  {jobType.value}
                </option>
              )
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderMinimumExperienceField
   * @returns {Component}
   */
  _renderMinimumExperienceField() {
    const { xpLvls } = this.state
    const {
      jobDetail: { xpLvlKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="xpLvlKey">Minimum Experience</Label>
        <Input
          type="select"
          name="xpLvlKey"
          id="xpLvlKey"
          value={xpLvlKey}
          onChange={handleChange}
          disabled={isInvalid}
        >
          {xpLvls &&
            xpLvls.map((xpLvl, index) => {
              return (
                <option key={index} value={xpLvl.key}>
                  {xpLvl.value}
                </option>
              )
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderMinimumQualificationField
   * @returns {Component}
   */
  _renderMinimumQualificationField() {
    const { degrees } = this.state
    const {
      jobDetail: { degreeKey },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="degreeKey">Minimum Qualification</Label>
        <Input
          type="select"
          name="degreeKey"
          id="degreeKey"
          value={degreeKey}
          onChange={handleChange}
          disabled={isInvalid}
        >
          {degrees &&
            degrees.map((degree, index) => {
              return (
                <option key={index} value={degree.key}>
                  {degree.value}
                </option>
              )
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderMonthlySalaryFromField
   * @returns {Component}
   */
  _renderCurrencyField = () => {
    const {
      handleChange,
      isInvalid,
      jobDetail: { jobTypeKey, salaryTypeId }
    } = this.props

    return (
      <FormGroup>
        <Label>Salary Type</Label>

        <Input
          disabled={isInvalid}
          type="select"
          name="salaryTypeId"
          value={salaryTypeId}
          onChange={(...props) => {
            // console.log({ props })
            handleChange(...props)
            this.setState(pre => {
              return { ...pre, refresh: Math.random() }
            })
          }}
        >
          {getSalariesTypeOptions(jobTypeKey).map((item, index) => (
            <option key={`country-${index}`} value={item.id}>
              {item.value}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  }

  _renderCurrencyFieldType = () => {
    const { currencies } = this.state

    const { jobDetail, handleChange, updateLocalJobDetailState } = this.props
    const { currencyId = 1, salaryTypeId } = jobDetail || {}
    const { fromName, toName } = getCurrentShouldShowObject(
      salaryTypeId,
      jobDetail
    )

    return (
      <FormGroup>
        <Label>Currency type</Label>
        <Input
          // disabled={isInvalid}
          type="select"
          name="currencyId"
          value={currencyId}
          onChange={(...props) => {
            handleChange(...props)
            updateLocalJobDetailState(fromName, '')
            updateLocalJobDetailState(toName, '')
          }}
        >
          {(currencies || []).map((item, index) => (
            <option key={`currency-${index}`} value={item.id}>
              {item.value}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  }
  /**
   *
   * @function _renderMonthlySalaryFromField
   * @returns {Component}
   */
  _renderMonthlySalaryFromField = () => {
    const {
      jobDetail,
      handleChange,
      isInvalid,
      updateLocalJobDetailState
    } = this.props
    const { currencyId = 1, salaryTypeId } = jobDetail || {}
    const {
      currencyIdToSalaryRangesMap,
      currentSalaryFrom,
      fromName,
      toName
    } = getCurrentShouldShowObject(salaryTypeId, jobDetail)

    console.log('jobDetail', jobDetail)
    // console.log('toName', currentSalaryFrom, currencyIdToSalaryRangesMap, toName, fromName, this.props)

    return (
      <FormGroup>
        <Label>Salary From</Label>
        <Input
          type="select"
          name={fromName}
          value={currentSalaryFrom} //
          onChange={e => {
            handleChange(e)
            const currency = currencyIdToSalaryRangesMap[currencyId]
            let salaryTo = null
            if (e.target.value != '') {
              salaryTo = Number(e.target.value) + Number(currency.interval)
            }
            updateLocalJobDetailState(toName, salaryTo)
            this.setState(pre => {
              return { ...pre, refresh: Math.random() }
            })
          }}
          disabled={isInvalid}
        >
          {/* {this.renderSalaryOption(10000, 200000)} */}
          {currencyIdToSalaryRangesMap &&
            getSalaryOption(
              currencyIdToSalaryRangesMap[currencyId],
              'lower'
            ).map(salary => (
              <option key={`salary-start-${salary}`} value={salary}>
                {salary}
              </option>
            ))}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderMonthlySalaryToField
   * @returns {Component}
   */
  _renderMonthlySalaryToField = () => {
    const { isInvalid, jobDetail, handleChange } = this.props

    const { currencyId = 1, salaryTypeId } = jobDetail || {}
    // console.log('currencyId', currencyId)

    const {
      currencyIdToSalaryRangesMap,
      currentSalaryFrom,
      currentSalaryTo,
      toName
    } = getCurrentShouldShowObject(salaryTypeId, jobDetail)

    const options =
      (currencyIdToSalaryRangesMap &&
        currentSalaryFrom &&
        getSalaryOption(
          currencyIdToSalaryRangesMap[currencyId],
          'upper'
        ).filter(
          salary =>
            salary > currentSalaryFrom && salary <= currentSalaryFrom * 2
        )) ||
      []

    // console.log('options options', jobDetail,
    //   {
    //     currencyIdToSalaryRangesMap,
    //     currentSalaryFrom,
    //     currentSalaryTo,
    //     toName,
    //     options
    //   })
    // console.log('options', options, currencyIdToSalaryRangesMap[currencyId])

    return (
      <FormGroup>
        <Label>Salary To</Label>
        <Input
          type="select"
          name={toName}
          value={currentSalaryTo}
          onChange={handleChange}
          disabled={isInvalid}
        >
          <option key={`salary-end-${null}`} value={null}>
            {null}
          </option>
          {options.map(salary => (
            <option key={`salary-end-${salary}`} value={salary}>
              {salary}
            </option>
          ))}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderStockBenefitsField
   * @returns {Component}
   */
  _renderStockBenefitsField() {
    const {
      jobDetail: { jobBenefits },
      handleJobBenefitsFieldChange
    } = this.props
    const { benefits } = this.state
    return (
      <FormGroup
        style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}
      >
        {benefits &&
          benefits
            .filter(benefit => benefit.category === 'Stock')
            .map(({ id, name }) => {
              return (
                <div key={id} style={{ display: 'flex', marginRight: '50px' }}>
                  <Label>
                    <Input
                      type="checkbox"
                      name="jobBenefits"
                      value={id}
                      checked={
                        jobBenefits &&
                        jobBenefits.filter(
                          jobBenefit => jobBenefit.benefitId === Number(id)
                        ).length > 0
                          ? true
                          : false
                      }
                      onChange={handleJobBenefitsFieldChange}
                    />
                    {name}
                  </Label>
                </div>
              )
            })}
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderVariableBenefitsField
   * @returns {Component}
   */
  _renderVariableBenefitsField() {
    const {
      jobDetail: { jobBenefits },
      handleJobBenefitsFieldChange
    } = this.props
    const { benefits } = this.state
    return (
      <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
        {benefits &&
          benefits
            .filter(benefit => benefit.category === 'Variable')
            .map(({ id, name }) => {
              return (
                <div key={id} style={{ display: 'flex', marginRight: '50px' }}>
                  <Label>
                    <Input
                      type="checkbox"
                      name="jobBenefits"
                      value={id}
                      checked={
                        jobBenefits &&
                        jobBenefits.filter(
                          jobBenefit => jobBenefit.benefitId === Number(id)
                        ).length > 0
                          ? true
                          : false
                      }
                      onChange={handleJobBenefitsFieldChange}
                    />
                    {name}
                  </Label>
                </div>
              )
            })}
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderAllowancesField
   * @returns {Component}
   */
  _renderAllowancesField() {
    const {
      jobDetail: { jobBenefits },
      handleJobBenefitsFieldChange
    } = this.props
    const { benefits } = this.state
    return (
      <FormGroup
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
      >
        {benefits &&
          benefits
            .filter(benefit => benefit.category === 'Allowance')
            .map(({ id, name }) => {
              return (
                <div key={id} style={{ display: 'flex', marginRight: '50px' }}>
                  <Label>
                    <Input
                      type="checkbox"
                      name="jobBenefits"
                      value={id}
                      checked={
                        jobBenefits &&
                        jobBenefits.filter(
                          jobBenefit => jobBenefit.benefitId === Number(id)
                        ).length > 0
                          ? true
                          : false
                      }
                      onChange={handleJobBenefitsFieldChange}
                    />
                    {name}
                  </Label>
                </div>
              )
            })}
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderWorkLocationField
   * @returns {Component}
   */
  _renderWorkLocationField() {
    const { locations } = this.state
    const {
      jobDetail: { jobLocationId, countryId },
      handleChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobLocationId">Work Location</Label>
        <Input
          type="select"
          name="jobLocationId"
          id="jobLocationId"
          value={jobLocationId}
          onChange={handleChange}
          disabled={isInvalid}
        >
          <option value={undefined} disabled={jobLocationId != null}>
            Not Selected
          </option>
          {locations.map((data, index) => {
            if (data.country.id == countryId) {
              return (
                <option key={index} value={data.location.id}>
                  {data.location.value} ({data.regionObj.display_name})
                </option>
              )
            }
          })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderCountryField
   * @returns {Component}
   */
  _renderCountryField() {
    const {
      jobDetail: { countryId },
      handleChange
      // isInvalid
    } = this.props

    return (
      <FormGroup>
        <Label for="jobCountryKey">Country</Label>
        <Input
          type="select"
          name="countryId"
          id="countryId"
          value={countryId}
          onChange={handleChange}
        >
          {this.state.countries &&
            this.state.countries.map((country, index) => {
              if (country.is_bossjob_supported === true) {
                return (
                  <option key={index} value={country.id}>
                    {country.value}
                  </option>
                )
              }
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderAddressField
   * @returns {Component}
   */
  _renderAddressField() {
    const {
      jobDetail: { floorUnit, address, longitude, latitude },
      workAddresses,
      defaultWorkAddress,
      handleWorkAddressChange,
      isInvalid
    } = this.props
    const matchWorkAddress = workAddresses.filter(
      workAddress =>
        workAddress.floorUnit == floorUnit &&
        workAddress.address == address &&
        workAddress.longitude == longitude &&
        workAddress.latitude == latitude
    )

    const isDefaultWorkAddressInList =
      defaultWorkAddress &&
      workAddresses.filter(
        workAddress =>
          workAddress.floorUnit == defaultWorkAddress.floorUnit &&
          workAddress.address == defaultWorkAddress.address &&
          workAddress.longitude == defaultWorkAddress.longitude &&
          workAddress.latitude == defaultWorkAddress.latitude
      ).length > 0
    return (
      <FormGroup>
        <Label for="address">Working Address* (floor/unit, address)</Label>
        <Input
          type="select"
          name="work-address"
          id="work-address"
          value={
            matchWorkAddress.length > 0
              ? matchWorkAddress[0].id
              : 'defaultAddress'
          }
          onChange={handleWorkAddressChange}
          disabled={isInvalid}
        >
          {defaultWorkAddress && !isDefaultWorkAddressInList && (
            <option key="work_address_default" value="defaultAddress">
              {[defaultWorkAddress.floorUnit, defaultWorkAddress.address].join(
                ', '
              )}
            </option>
          )}
          {workAddresses &&
            workAddresses.map(workAddress => {
              return (
                <option
                  key={`work_address_${workAddress.id}`}
                  value={workAddress.id}
                >
                  {[workAddress.floorUnit, workAddress.address].join(', ')}
                </option>
              )
            })}
        </Input>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderLongitudeField
   * @returns {Component}
   */
  _renderLongitudeField() {
    const {
      jobDetail: { longitude }
    } = this.props
    return (
      <FormGroup>
        <Label for="longitude">Longitude: {longitude}</Label>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderLatitudeField
   * @returns {Component}
   */
  _renderLatitudeField() {
    const {
      jobDetail: { latitude }
    } = this.props
    return (
      <FormGroup>
        <Label for="latitude">Latitude: {latitude}</Label>
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobDescriptionField
   * @returns {Component}
   */
  _renderJobDescriptionField() {
    const {
      jobDetail: { jobDescriptionHtml },
      handleTextEditorChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobDescription">Job Description</Label>
        <TextEditorField
          name="jobDescription"
          value={jobDescriptionHtml}
          handleChange={(htmlValue, textValue) =>
            handleTextEditorChange('jobDescription', textValue, htmlValue)
          }
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderJobRequirementField
   * @returns {Component}
   */
  _renderJobRequirementField() {
    const {
      jobDetail: { jobRequirementsHtml },
      handleTextEditorChange,
      isInvalid
    } = this.props
    return (
      <FormGroup>
        <Label for="jobRequirements">Job Requirements</Label>
        <TextEditorField
          name="jobRequirements"
          value={jobRequirementsHtml}
          handleChange={(htmlValue, textValue) =>
            handleTextEditorChange('jobRequirements', textValue, htmlValue)
          }
          disabled={isInvalid}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderScreeningQuestionField
   * @returns {Component}
   */
  _renderScreeningQuestionField() {
    const {
      jobDetail: { screeningQuestions },
      recruiterSubscriptionPlanDetail,
      handleDynamicInputFieldChange,
      isInvalid
    } = this.props
    const keyName = 'screeningQuestions'
    if (
      recruiterSubscriptionPlanDetail &&
      recruiterSubscriptionPlanDetail.maxNoOfScreeningQuestionsPerJob
    ) {
      const {
        maxNoOfScreeningQuestionsPerJob
      } = recruiterSubscriptionPlanDetail

      return (
        <FormGroup>
          <Label for="screeningQuestion">Screening Question</Label>
          {screeningQuestions.map((nothing, index) => (
            <React.Fragment key={index}>
              <Row>
                <Col md="1">
                  <Label for="screeningQuestion">{index + 1}:</Label>
                </Col>
                <Col md={index > 0 ? '10' : '11'}>
                  <Input
                    type="text"
                    name="screeningQuestion"
                    id="screeningQuestion"
                    value={screeningQuestions[index].question}
                    onChange={e =>
                      handleDynamicInputFieldChange(
                        keyName,
                        'edit',
                        e.target.value,
                        index
                      )
                    }
                    disabled={isInvalid}
                  />
                </Col>
                {index > 0 && (
                  <Col md="1">
                    <div
                      style={{
                        position: 'absolute',
                        top: '7px',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        handleDynamicInputFieldChange(
                          keyName,
                          'delete',
                          null,
                          index
                        )
                      }
                    >
                      x
                    </div>
                  </Col>
                )}
              </Row>
            </React.Fragment>
          ))}
          {screeningQuestions.length < maxNoOfScreeningQuestionsPerJob && (
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                handleDynamicInputFieldChange(
                  keyName,
                  'add',
                  screeningQuestions.length
                )
              }
            >
              {`+ Add Screening Question (up to ${maxNoOfScreeningQuestionsPerJob} screening question)`}
            </div>
          )}
        </FormGroup>
      )
    }
  }

  _renderResumeEmailField() {
    const {
      jobDetail: { resumeForwardingEmails },
      recruiterSubscriptionPlanDetail,
      handleDynamicInputFieldChange,
      isInvalid
    } = this.props
    const keyName = 'resumeForwardingEmails'
    if (
      recruiterSubscriptionPlanDetail &&
      recruiterSubscriptionPlanDetail.maxNoOfEmailsToReceiveResumePerJob
    ) {
      const {
        maxNoOfEmailsToReceiveResumePerJob
      } = recruiterSubscriptionPlanDetail
      return (
        <FormGroup>
          <Label for="resumeForwardingEmail">Resume Forwarding Email</Label>
          {resumeForwardingEmails.map((nothing, index) => (
            <React.Fragment key={index}>
              <Row>
                <Col md="1">
                  <Label for="resumeForwardingEmail">{index + 1}:</Label>
                </Col>
                <Col md={index > 0 ? '10' : '11'}>
                  <Input
                    type="text"
                    name="resumeForwardingEmail"
                    id="resumeForwardingEmail"
                    value={resumeForwardingEmails[index].email}
                    onChange={e =>
                      handleDynamicInputFieldChange(
                        keyName,
                        'edit',
                        e.target.value,
                        index
                      )
                    }
                    disabled={isInvalid}
                  />
                </Col>
                {index > 0 && (
                  <Col md="1">
                    <div
                      style={{
                        position: 'absolute',
                        top: '7px',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        handleDynamicInputFieldChange(
                          keyName,
                          'delete',
                          null,
                          index
                        )
                      }
                    >
                      x
                    </div>
                  </Col>
                )}
              </Row>
            </React.Fragment>
          ))}
          {resumeForwardingEmails.length <
            maxNoOfEmailsToReceiveResumePerJob && (
            <div
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                handleDynamicInputFieldChange(
                  keyName,
                  'add',
                  resumeForwardingEmails.length
                )
              }
            >
              {`+ Add resume forwarding email (up to ${maxNoOfEmailsToReceiveResumePerJob} email)`}
            </div>
          )}
        </FormGroup>
      )
    }
  }

  /**
   *
   * @function _renderSkillsField
   * @returns {Component}
   */
  _renderSkillsField() {
    const {
      jobDetail: { jobSkills },
      updateLocalJobDetailState
    } = this.props
    return (
      <FormGroup>
        <Skills
          type="jobDetail"
          skills={jobSkills}
          handleChange={value => updateLocalJobDetailState('jobSkills', value)}
        />
      </FormGroup>
    )
  }

  /**
   *
   * @function _renderWorkArrangementField
   * @returns {Component}
   */
  _renderWorkArrangementField() {
    const {
      workArrangements,
      locations,
      isRemote,
      countries,
      languages,
      timezones,
      salaryRanges
    } = this.state

    const {
      jobDetail: {
        workArrangementId,
        jobLocationId,
        countryId,
        remoteCountryIds,
        isRemoteWorldwide,
        languageIds,
        timezoneIds,
        isAllLocation
      },
      handleChange,
      isInvalid,
      updateLocalJobDetailState
    } = this.props

    let valueLocation = isAllLocation ? 'isAllLocation' : jobLocationId

    const currencyIdToSalaryRangesMap =
      salaryRanges &&
      Object.fromEntries(salaryRanges.map(i => [i.currency_id, i]))

    const handleWorkArrangementChange = e => {
      const prevRemote = isRemote
      const curRemote = Boolean(e.target.value == 3)
      // console.log(isRemote)
      this.setState({
        isRemote: curRemote
      })

      if (curRemote !== prevRemote && !curRemote) {
        updateLocalJobDetailState('currencyId', 1)
        updateLocalJobDetailState('countryId', 167)
        updateLocalJobDetailState('salaryRangeFrom', 10000)
        updateLocalJobDetailState('salaryRangeFrom', 15000)
      }
      handleChange(e)
    }

    const handleSelection = (selectedOptions, key) => {
      updateLocalJobDetailState(key, selectedOptions)
    }
    const handleSelectWorldwide = () => {
      updateLocalJobDetailState('isRemoteWorldwide', !isRemoteWorldwide)
    }

    const handleCountryChange = e => {
      const curCurrencyId =
        countries &&
        countries.filter(country => country.id == e.target.value)[0].currency_id
      const curSalaryRange = currencyIdToSalaryRangesMap[curCurrencyId]
      if (curCurrencyId) {
        updateLocalJobDetailState('currencyId', curCurrencyId)
        updateLocalJobDetailState('salaryRangeFrom', curSalaryRange.from)
        updateLocalJobDetailState(
          'salaryRangeTo',
          curSalaryRange.from + curSalaryRange.interval
        )
      }
      handleChange(e)
    }

    return (
      <>
        <FormGroup>
          <Label for="workArrangementId">Work Arrangement</Label>
          <Input
            type="select"
            name="workArrangementId"
            id="workArrangementId"
            value={workArrangementId}
            onChange={handleWorkArrangementChange}
          >
            {workArrangements &&
              workArrangements.map(workArrangement => {
                return (
                  <option key={workArrangement.name} value={workArrangement.id}>
                    {workArrangement.name}
                  </option>
                )
              })}
          </Input>
        </FormGroup>
        {// Non remote settings: country, locations
        !isRemote && (
          <FormGroup>
            <Row>
              <Col md="6">
                <Label for="jobCountryKey">Country</Label>
                <Input
                  type="select"
                  name="countryId"
                  id="countryId"
                  value={countryId}
                  onChange={handleCountryChange}
                >
                  {this.state.countries &&
                    this.state.countries.map((country, index) => {
                      if (country.is_bossjob_supported === true) {
                        return (
                          <option key={index} value={country.id}>
                            {country.id} {country.value}
                          </option>
                        )
                      }
                    })}
                </Input>
              </Col>
              <Col md="6">
                <Label for="jobLocationId">Work Location</Label>
                <Input
                  type="select"
                  name="jobLocationId"
                  id="jobLocationId"
                  value={valueLocation}
                  onChange={handleChange}
                  disabled={isInvalid}
                >
                  <option value={undefined} disabled={valueLocation != null}>
                    Not Selected
                  </option>
                  {locations.map((data, index) => {
                    if (data.country.id == countryId) {
                      return (
                        <option key={index} value={data.location.id}>
                          {data.location.value} ({data.regionObj.display_name})
                        </option>
                      )
                    }
                  })}
                  {countryId == 193 && (
                    <option value="isAllLocation">All Regions</option>
                  )}
                </Input>
              </Col>
            </Row>
          </FormGroup>
        )}
        {// Remote settings: remote countries, timezones, languages
        isRemote && (
          <FormGroup>
            <Row>
              <Col md="4">
                <Label for="remoteCountries">Remote Countries</Label>
                {!isRemoteWorldwide && (
                  <MultiSelectDropdown
                    field="remoteCountryIds"
                    options={countries}
                    onSelect={handleSelection}
                    ids={remoteCountryIds}
                  />
                )}
                <div style={{ display: 'flex', marginLeft: '20px' }}>
                  <Label>
                    <Input
                      type="checkbox"
                      name="isRemoteWorldwide"
                      value={isRemoteWorldwide}
                      checked={isRemoteWorldwide}
                      onChange={handleSelectWorldwide}
                    />
                    Select worldwide
                  </Label>
                </div>
              </Col>
              <Col md="4">
                <Label for="jobLanguages">Remote Job Languages</Label>
                <MultiSelectDropdown
                  field="languageIds"
                  options={languages}
                  onSelect={handleSelection}
                  ids={languageIds}
                />
              </Col>
              {/* this timezone field is no longer supported */}
              {/* <Col md="4">
                <Label for="timezones">Remote Job Timezones</Label>
                <MultiSelectDropdown
                  field="timezoneIds"
                  options={timezones}
                  onSelect={handleSelection}
                  ids={timezoneIds}
                />
              </Col> */}
            </Row>
          </FormGroup>
        )}
      </>
    )
  }

  /**
   *
   * @function _renderRemoteCountriesField
   * @returns {Component}
   */
  _renderRemoteCountriesField() {
    const {
      jobDetail: { remoteCountryIds, isRemoteWorldwide },
      updateLocalJobDetailState
    } = this.props
    const { countries } = this.state
    const options = countries
    const handleSelection = selectedOptions => {
      updateLocalJobDetailState('remoteCountryIds', selectedOptions)
    }
    const handleSelectWorldwide = () => {
      updateLocalJobDetailState('isRemoteWorldwide', !isRemoteWorldwide)
    }

    return (
      <FormGroup>
        <Label for="remoteCountries">Remote Countries</Label>
        {!isRemoteWorldwide && (
          <MultiSelectDropdown
            options={options}
            onSelect={handleSelection}
            remoteCountryIds={remoteCountryIds}
          />
        )}
        <div style={{ display: 'flex', marginLeft: '20px' }}>
          <Label>
            <Input
              type="checkbox"
              name="isRemoteWorldwide"
              value={isRemoteWorldwide}
              checked={isRemoteWorldwide}
              onChange={handleSelectWorldwide}
            />
            Select worldwide
          </Label>
        </div>
      </FormGroup>
    )
  }

  render() {
    const {
      jobDetail: { workArrangementId }
    } = this.props

    const isRemote = Number(workArrangementId) === 3

    return (
      <SectionWrapper sectionTitle="Job">
        <Form>
          <Container>
            <Row>
              <Col md="6">{this._renderJobTitleField()}</Col>
              <Col md="6">{this._renderJobTitleRefField()}</Col>
            </Row>

            <Row>
              <Col md="6">{this._renderJobFunctionTitleField()}</Col>
              <Col md="6">{this._renderJobTypeField()}</Col>
            </Row>

            <Row>
              <Col md="6">{this._renderMinimumExperienceField()}</Col>
              <Col md="6">{this._renderMinimumQualificationField()}</Col>
            </Row>

            <Row>
              <Col>{this._renderWorkArrangementField()}</Col>
            </Row>

            <Row>
              <Col md="12">{this._renderAddressField()}</Col>
            </Row>
            <Row>
              <Col md="6">{this._renderLongitudeField()}</Col>
              <Col md="6">{this._renderLatitudeField()}</Col>
            </Row>

            <Row>
              <Col md="2">{this._renderCurrencyField()}</Col>
              {isRemote && <Col md="2">{this._renderCurrencyFieldType()}</Col>}
              <Col md="5">{this._renderMonthlySalaryFromField()}</Col>
              <Col md="5">{this._renderMonthlySalaryToField()}</Col>
            </Row>

            <Row style={{ alignItems: 'baseline' }}>
              <Col md="3">Stock benefits:</Col>
              <Col md="9">{this._renderStockBenefitsField()}</Col>
            </Row>

            <Row>
              <Col md="3">Variable benefits:</Col>
              <Col md="9">{this._renderVariableBenefitsField()}</Col>
            </Row>

            <Row>
              <Col md="3">Allowances:</Col>
              <Col md="9">{this._renderAllowancesField()}</Col>
            </Row>

            <Row>
              <Col md="12">{this._renderJobDescriptionField()}</Col>
            </Row>

            <Row>
              <Col md="12">{this._renderJobRequirementField()}</Col>
            </Row>

            <Row>
              <Col md="12">{this._renderResumeEmailField()}</Col>
            </Row>

            <Row>
              <Col md="12">{this._renderSkillsField()}</Col>
            </Row>
          </Container>
        </Form>
      </SectionWrapper>
    )
  }
}

export default JobDetailInformationFormContainer
