import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import { GET_JOB_BY_ID } from '../../schemas/queries'
import { GET_RECRUITER_SUBSCRIPTION_PLAN_DETAIL } from '../../../bossjob_users/schemas/queries'
import {
  UPDATE_LOCAL_JOB_DETAILS,
  UPDATE_LOCAL_JOB_CATEGORIES,
  UPDATE_LOCAL_SCREENING_QUESTIONS,
  UPDATE_LOCAL_JOB_BENEFITS,
  UPDATE_JOB,
  UPDATE_JOB_STATUS,
  REFRESH_JOB,
  UPDATE_SALARY_STATUS,
  RESTORE_JOB,
  ADD_LOCAL_DYNAMIC_INPUT_FIELD,
  DELETE_LOCAL_DYNAMIC_INPUT_FIELD,
  UPDATE_LOCAL_JOB_FORWARDING_EMAIL
} from '../../schemas/mutations'

/* Bootstrap */
import { Col, Row, Alert, Button } from 'reactstrap'

/* Components */
import Loading from 'shared/components/Loading'
import SectionWrapper from 'shared/components/SectionWrapper'

/* Form Components */
import JobDetailPublishFormContainer from './JobDetailPublishFormContainer'
import JobDetailBoostFormContainer from './JobDetailBoostFormContainer'
import JobDetailInformationFormContainer from './JobDetailInformationFormContainer'
import JobStat from '../../views/JobDetail/JobStat'
// import JobDetailStatContainer from './JobDetailStatContainer'
// import JobRoboHeadhuntInformationContainer from './JobRoboHeadhuntInformationContainer'

/* Styles */
import styles from './JobDetailContainer.module.scss'
import { GET_COMPANY_WORKING_ADDRESS_LIST } from '../../../companies/schemas/queries'

/**
 * JobDetailContainer - Apollo Component to fetch the job details
 *
 * @returns {Component}
 */
class JobDetailContainer extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    updateLocalJobDetails: PropTypes.func.isRequired,
    updateJobDetails: PropTypes.func.isRequired,
    updateJobStatus: PropTypes.func.isRequired,
    updateLocalJobCategories: PropTypes.func.isRequired,
    updateLocalJobBenefits: PropTypes.func.isRequired,
    updateLocalScreeningQuestions: PropTypes.func.isRequired,
    updateLocalJobForwardingEmails: PropTypes.func.isRequired,
    addLocalDynamicInputField: PropTypes.func.isRequired,
    deleteLocalDynamicInputField: PropTypes.func.isRequired,
    refreshJob: PropTypes.func.isRequired,
    updateSalaryStatus: PropTypes.func.isRequired,
    restoreJob: PropTypes.func.isRequired,
    updateError: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      jobId: props.match.params.jobId,
      error: false,
      errorMessage: '',
      defaultWorkAddress: null
    }

    this.updateError = this.updateError.bind(this)
    this.handleExternalURLChange = this.handleExternalURLChange.bind(this)
    this.handleTextEditorChange = this.handleTextEditorChange.bind(this)
    this.updateLocalJobDetailState = this.updateLocalJobDetailState.bind(this)
    this.handleJobFieldChange = this.handleJobFieldChange.bind(this)
    this.handleBoostJobCheckBoxChange = this.handleBoostJobCheckBoxChange.bind(
      this
    )
  }

  updateError(error, errorMessage) {
    this.setState({
      error: error,
      errorMessage: errorMessage
    })
  }

  /**
   *
   * @function _updateLocalJobDetailState - Private Method to update Apollo Local Job Detail State
   * @param {String} key
   * @param {String} value
   */
  updateLocalJobDetailState(key, value) {
    const { updateLocalJobDetails } = this.props
    updateLocalJobDetails({
      variables: {
        key: key,
        value: value
      }
    })
  }

  getSalaryInterval(currencyId) {
    const currencyIntervalMapping = {
      1: 5000,
      2: 100,
      5: 20000,
      4: 200000,
      6: 1000,
      7: 1000,
      8: 1000,
      9: 200,
      3: 200
    }
    return currencyIntervalMapping[currencyId]
  }

  /**
   *
   * @function handleJobFieldChange - Public method to be hand down to children prop
   * @param {InputEvent} e
   */
  handleJobFieldChange(e, jobDetail) {
    if (e.target.name === 'salaryRangeFrom') {
      if (jobDetail) {
        this.updateLocalJobDetailState(
          'salaryRangeTo',
          Number(e.target.value) +
            Number(this.getSalaryInterval(jobDetail.currencyId))
        )
      }
    }
    this.updateLocalJobDetailState(e.target.name, e.target.value)
  }

  handleWorkAddressChange = (e, workAddressData) => {
    if (e.target.name === 'work-address') {
      const workAddressId = e.target.value
      const selectedWorkAddresses = workAddressData.filter(
        workAddress => Number(workAddress.id) === Number(workAddressId)
      )
      const selectedWorkAddress =
        selectedWorkAddresses.length > 0
          ? selectedWorkAddresses[0]
          : this.state.defaultWorkAddress
      const { floorUnit, address, latitude, longitude } = selectedWorkAddress
      this.updateLocalJobDetailState('floorUnit', floorUnit)
      this.updateLocalJobDetailState('address', address)
      this.updateLocalJobDetailState('longitude', longitude)
      this.updateLocalJobDetailState('latitude', latitude)
    }
  }

  handleJobBenefitsFieldChange = e => {
    this.props.updateLocalJobBenefits({
      variables: {
        jobBenefitId: e.target.value,
        isChecked: e.target.checked
      }
    })
  }

  handleDynamicInputFieldChange = (keyName, action, value, index) => {
    const {
      updateLocalJobCategories,
      updateLocalScreeningQuestions,
      updateLocalJobForwardingEmails,
      addLocalDynamicInputField,
      deleteLocalDynamicInputField
    } = this.props
    if (action === 'add') {
      let obj
      switch (keyName) {
        case 'jobCategories':
          obj = {
            id: null,
            __typename: 'JobsCategoriesType',
            categoryId: value,
            category: {
              __typename: 'JobCategoryListType',
              id: value,
              value: ''
            }
          }
          break
        case 'screeningQuestions':
          obj = {
            id: value,
            __typename: 'JobScreeningQuestionType',
            question: null
          }
          break
        case 'resumeForwardingEmails':
          obj = {
            id: value,
            __typename: 'JobResumeForwardingEmailType',
            email: null
          }
          break
      }
      addLocalDynamicInputField({
        variables: {
          keyName: keyName,
          obj: obj
        }
      })
    } else if (action === 'edit') {
      switch (keyName) {
        case 'jobCategories':
          updateLocalJobCategories({
            variables: {
              categoryId: value,
              index: index
            }
          })
          break
        case 'screeningQuestions':
          updateLocalScreeningQuestions({
            variables: {
              question: value,
              index: index
            }
          })
          break
        case 'resumeForwardingEmails':
          updateLocalJobForwardingEmails({
            variables: {
              email: value,
              index: index
            }
          })
          break
      }
    } else if (action === 'delete') {
      deleteLocalDynamicInputField({
        variables: {
          keyName: keyName,
          index: index
        }
      })
    }
  }

  /**
   *
   * @function handleJobFieldChange - Public method to be hand down to children prop specifically
   * for editing External URL
   * @param {String} URL
   */
  handleExternalURLChange(URL) {
    this.updateLocalJobDetailState('externalApplyUrl', URL)
  }

  /**
   *
   * @function handleTextEditorChange - Public method to be hand down to children prop specifically
   * for editing `React-Quill` components
   * @param {String} key
   * @param {String} textValue
   * @param {String} htmlValue
   */
  handleTextEditorChange(key, textValue, htmlValue) {
    this.updateLocalJobDetailState(`${key}`, textValue)
    this.updateLocalJobDetailState(`${key}Html`, htmlValue)
  }

  /**
   *
   * @function handleBoostJobCheckBoxChange - Public method to be hand down to children prop specifically
   * for editing CheckBox components
   * @param {String} key
   * @param {String} value
   */
  handleBoostJobCheckBoxChange(key, value) {
    if (key === 'featured') {
      this.updateLocalJobDetailState('stickies', {
        status: value,
        __typename: 'stickies'
      })
    } else if (key === 'urgent') {
      this.updateLocalJobDetailState('highlighted', value)
    } else {
      this.updateLocalJobDetailState(key, value)
    }
  }

  duplicateJob = (recruiterId, jobId) => {
    window.location.href = `/bossjob/recruiter/${recruiterId}/job/new?jobId=${jobId}`
  }

  backToRecruiterJobs = recruiterId => {
    window.location.href = `/bossjob/user/${recruiterId}/recruiter/jobs/1`
  }

  render() {
    const { jobId } = this.state
    const {
      updateJobDetails,
      updateJobStatus,
      refreshJob,
      updateSalaryStatus,
      restoreJob
    } = this.props

    return (
      <Query query={GET_JOB_BY_ID} variables={{ jobId }}>
        {({ loading, data: { getJob }, client }) => {
          if (loading || !getJob) {
            return <Loading />
          }
          if (getJob && getJob.data) {
            if (!this.state.defaultWorkAddress) {
              this.setState({
                defaultWorkAddress: {
                  floorUnit: getJob.data.floorUnit,
                  address: getJob.data.address,
                  longitude: getJob.data.longitude,
                  latitude: getJob.data.latitude
                }
              })
            }
            client.writeData({
              data: {
                jobDetail: getJob.data
              }
            })

            let isInvalid = false

            if (getJob.data.deletedAt !== null) {
              isInvalid = true
            }
            const companyId = getJob.data.companyId
            return (
              <Query
                query={GET_RECRUITER_SUBSCRIPTION_PLAN_DETAIL}
                variables={{ userId: getJob.data.recruiterId }}
              >
                {({ loading, data: { userSubscriptionPlan } }) => {
                  if (loading) return <Loading />
                  return (
                    <Query
                      query={GET_COMPANY_WORKING_ADDRESS_LIST}
                      variables={{ companyId }}
                    >
                      {({ loading, data: { getCompanyWorkingAddresses } }) => {
                        if (loading) return <Loading />
                        return (
                          <>
                            {getJob.data.deletedAt !== null && (
                              <Row>
                                <Col lg={11}>
                                  <Alert color="danger">
                                    This job is deleted
                                  </Alert>
                                </Col>
                              </Row>
                            )}
                            {this.state.error && (
                              <Row>
                                <Col lg={11}>
                                  <Alert color="danger">
                                    {this.state.errorMessage}
                                  </Alert>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col md="8">
                                <JobDetailInformationFormContainer
                                  recruiterSubscriptionPlanDetail={
                                    userSubscriptionPlan
                                      ? userSubscriptionPlan.data
                                      : null
                                  }
                                  jobDetail={getJob.data}
                                  workAddresses={
                                    getCompanyWorkingAddresses.data
                                  }
                                  defaultWorkAddress={
                                    this.state.defaultWorkAddress
                                  }
                                  handleChange={(e, index) => {
                                    if (e.target.name == 'jobLocationId') {
                                      if (e.target.value == 'isAllLocation') {
                                        this.updateLocalJobDetailState(
                                          'isAllLocation',
                                          true
                                        )
                                        this.updateLocalJobDetailState(
                                          'jobLocationId',
                                          null
                                        )
                                      }

                                      if (e.target.value != 'isAllLocation') {
                                        this.updateLocalJobDetailState(
                                          'isAllLocation',
                                          false
                                        )
                                        this.updateLocalJobDetailState(
                                          'jobLocationId',
                                          e.target.value
                                        )
                                      }
                                    } else {
                                      // debugger
                                      this.handleJobFieldChange(
                                        e,
                                        getJob.data,
                                        index
                                      )
                                    }
                                  }}
                                  handleWorkAddressChange={e =>
                                    this.handleWorkAddressChange(
                                      e,
                                      getCompanyWorkingAddresses.data
                                    )
                                  }
                                  handleTextEditorChange={
                                    this.handleTextEditorChange
                                  }
                                  isInvalid={isInvalid}
                                  updateLocalJobDetailState={
                                    this.updateLocalJobDetailState
                                  }
                                  handleDynamicInputFieldChange={
                                    this.handleDynamicInputFieldChange
                                  }
                                  handleJobBenefitsFieldChange={
                                    this.handleJobBenefitsFieldChange
                                  }
                                />
                                {/* <JobDetailStatContainer jobId={Number(jobId)} />
                                <JobRoboHeadhuntInformationContainer
                                  jobId={Number(jobId)}
                                /> */}
                              </Col>
                              <Col md="3">
                                <JobDetailBoostFormContainer
                                  recruiterSubscriptionPlanDetail={
                                    userSubscriptionPlan
                                      ? userSubscriptionPlan.data
                                      : null
                                  }
                                  jobDetail={getJob.data}
                                  handleChange={this.handleJobFieldChange}
                                  handleCheckBoxChange={
                                    this.handleBoostJobCheckBoxChange
                                  }
                                  handleExternalURLChange={
                                    this.handleExternalURLChange
                                  }
                                  updateSalaryStatus={updateSalaryStatus}
                                  isInvalid={isInvalid}
                                />
                                <Row />
                                <div className={styles.formSection}>
                                  <JobDetailPublishFormContainer
                                    jobDetail={getJob.data}
                                    client={client}
                                    handleChange={this.handleJobFieldChange}
                                    updateJobDetails={updateJobDetails}
                                    updateJobStatus={updateJobStatus}
                                    refreshJob={refreshJob}
                                    isInvalid={isInvalid}
                                    restoreJob={restoreJob}
                                    updateError={this.updateError}
                                  />
                                </div>
                                <SectionWrapper sectionTitle="Misc.">
                                  <div style={{ marginBottom: '22px' }}>
                                    <Button
                                      className={styles.button}
                                      color="warning"
                                      onClick={() =>
                                        this.duplicateJob(
                                          getJob.data.recruiterId,
                                          getJob.data.id
                                        )
                                      }
                                    >
                                      Duplicate Job
                                    </Button>
                                  </div>
                                  <div>
                                    <Button
                                      className={styles.button}
                                      color="info"
                                      onClick={() =>
                                        this.backToRecruiterJobs(
                                          getJob.data.recruiterId
                                        )
                                      }
                                    >
                                      {'Back to R\'s Job List'}
                                    </Button>
                                  </div>
                                </SectionWrapper>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={11}>
                                <SectionWrapper sectionTitle="Statistics">
                                  <JobStat jobDetail={getJob.data} />
                                </SectionWrapper>
                              </Col>
                            </Row>
                          </>
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            )
          }
        }}
      </Query>
    )
  }
}

export default compose(
  graphql(UPDATE_LOCAL_JOB_DETAILS, { name: 'updateLocalJobDetails' }),
  graphql(UPDATE_LOCAL_JOB_CATEGORIES, { name: 'updateLocalJobCategories' }),
  graphql(UPDATE_LOCAL_JOB_BENEFITS, { name: 'updateLocalJobBenefits' }),
  graphql(UPDATE_LOCAL_SCREENING_QUESTIONS, {
    name: 'updateLocalScreeningQuestions'
  }),
  graphql(UPDATE_LOCAL_JOB_FORWARDING_EMAIL, {
    name: 'updateLocalJobForwardingEmails'
  }),
  graphql(ADD_LOCAL_DYNAMIC_INPUT_FIELD, { name: 'addLocalDynamicInputField' }),
  graphql(DELETE_LOCAL_DYNAMIC_INPUT_FIELD, {
    name: 'deleteLocalDynamicInputField'
  }),
  graphql(UPDATE_JOB, { name: 'updateJobDetails' }),
  graphql(UPDATE_JOB_STATUS, { name: 'updateJobStatus' }),
  graphql(REFRESH_JOB, { name: 'refreshJob' }),
  graphql(RESTORE_JOB, { name: 'restoreJob' }),
  graphql(UPDATE_SALARY_STATUS, { name: 'updateSalaryStatus' })
)(withRouter(JobDetailContainer))
