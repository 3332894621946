import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

/* Routing */
import { withRouter } from 'react-router-dom'

/* Vendors */
import queryString from 'query-string'

/* Apollo */
import { Query, graphql, compose } from 'react-apollo'
import {
  UPDATE_LOCAL_POST_JOB_DETAILS,
  UPDATE_LOCAL_POST_JOB_CATEGORIES,
  UPDATE_LOCAL_POST_JOB_BENEFITS,
  UPDATE_LOCAL_POST_JOB_SCREENING_QUESTIONS,
  UPDATE_LOCAL_POST_JOB_FORWARDING_EMAILS,
  ADD_LOCAL_POST_JOB_DYNAMIC_INPUT_FIELD,
  DELETE_LOCAL_POST_JOB_DYNAMIC_INPUT_FIELD,
  POST_JOB
} from '../../schemas/mutations'

import {
  GET_JOB_BY_ID,
  GET_LOCAL_POST_JOB_DETAILS
} from '../../schemas/queries'
import { GET_RECRUITER_SUBSCRIPTION_PLAN_DETAIL } from '../../../bossjob_users/schemas/queries'
import { GET_COMPANY_WORKING_ADDRESS_LIST } from '../../../companies/schemas/queries'

/* Components */
import PostJobContainer from '../../containers/PostJobContainer'
import Loading from 'shared/components/Loading'

/* Helper */
import { getConfigList } from 'shared/helpers/configList'

const PostJob = ({
  match,
  updateLocalPostJobDetails,
  updateLocalPostJobBenefits,
  updateLocalPostJobCategories,
  updateLocalPostJobScreeningQuestions,
  updateLocalPostJobForwardingEmails,
  addLocalPostJobDynamicInputField,
  deleteLocalPostJobDynamicInputField,
  postJobDetail,
  postNewJob
}) => {
  const userId = Number(match.params.recruiterId)
  const [jobId, setJobId] = useState(null)
  const [companyIdParam, setCompanyIdParam] = useState(null)
  const [workAddressData, setWorkAddressData] = useState([])
  const [isGetJobLoaded, setIsGetJobLoaded] = useState(false)
  const [options, setOptions] = useState({})

  useEffect(() => {
    async function updateOptions() {
      const data = await getConfigList()
      const { job_function_lists } = data.inputs
      setOptions({
        jobFunctionList: job_function_lists
      })
    }
    updateOptions()
  }, [])

  useEffect(() => {
    const { jobId, companyId } = queryString.parse(location.search)
    if (jobId) {
      setJobId(jobId)
    }
    if (companyId) {
      setCompanyIdParam(companyId)
    }
  }, [])

  const defaultWorkAddress = useMemo(
    () => ({
      floorUnit:
        (postJobDetail.postJobDetail &&
          postJobDetail.postJobDetail.floorUnit) ||
        '',
      address:
        (postJobDetail.postJobDetail && postJobDetail.postJobDetail.address) ||
        '',
      longitude:
        (postJobDetail.postJobDetail &&
          postJobDetail.postJobDetail.longitude) ||
        '',
      latitude:
        (postJobDetail.postJobDetail && postJobDetail.postJobDetail.latitude) ||
        ''
    }),
    [isGetJobLoaded]
  )

  const updateLocalPostJobDetailState = (key, value) => {
    updateLocalPostJobDetails({
      variables: {
        key: key,
        value: value
      }
    })
  }

  const handleJobFieldChange = e => {
    if (e.target.name === 'salaryRangeFrom') {
      updateLocalPostJobDetailState(
        'salaryRangeTo',
        Number(e.target.value) + 5000
      )
    }

    if (e.target.name === 'work-address') {
      const workAddressId = e.target.value
      const selectedWorkAddresses = workAddressData.filter(
        workAddress => Number(workAddress.id) === Number(workAddressId)
      )
      const selectedWorkAddress =
        selectedWorkAddresses.length > 0
          ? selectedWorkAddresses[0]
          : defaultWorkAddress
      const { floorUnit, address, latitude, longitude } = selectedWorkAddress
      updateLocalPostJobDetailState('floorUnit', floorUnit)
      updateLocalPostJobDetailState('address', address)
      updateLocalPostJobDetailState('longitude', longitude)
      updateLocalPostJobDetailState('latitude', latitude)
    } else {
      updateLocalPostJobDetailState(e.target.name, e.target.value)
    }
  }

  const handleTextEditorChange = (key, textValue, htmlValue) => {
    updateLocalPostJobDetailState(`${key}`, textValue)
    updateLocalPostJobDetailState(`${key}Html`, htmlValue)
  }

  const handleJobBenefitsFieldChange = e => {
    updateLocalPostJobBenefits({
      variables: {
        jobBenefitId: e.target.value,
        isChecked: e.target.checked
      }
    })
  }

  const handleDynamicInputFieldChange = (keyName, action, value, index) => {
    if (action === 'add') {
      let obj
      switch (keyName) {
        case 'jobCategories':
          obj = {
            id: null,
            __typename: 'JobsCategoriesType',
            categoryId: value,
            category: {
              __typename: 'JobCategoryListType',
              id: value,
              value: ''
            }
          }
          break
        case 'screeningQuestions':
          obj = {
            id: value,
            __typename: 'JobScreeningQuestionType',
            question: null
          }
          break
        case 'resumeForwardingEmails':
          obj = {
            id: value,
            __typename: 'JobResumeForwardingEmailType',
            email: null
          }
          break
      }
      addLocalPostJobDynamicInputField({
        variables: {
          keyName: keyName,
          obj: obj
        }
      })
    } else if (action === 'edit') {
      switch (keyName) {
        case 'jobCategories':
          updateLocalPostJobCategories({
            variables: {
              categoryId: value,
              index: index
            }
          })
          break
        case 'screeningQuestions':
          updateLocalPostJobScreeningQuestions({
            variables: {
              question: value,
              index: index
            }
          })
          break
        case 'resumeForwardingEmails':
          updateLocalPostJobForwardingEmails({
            variables: {
              email: value,
              index: index
            }
          })
          break
      }
    } else if (action === 'delete') {
      deleteLocalPostJobDynamicInputField({
        variables: {
          keyName: keyName,
          index: index
        }
      })
    }
  }

  const handleBoostJobCheckBoxChange = (key, value) => {
    if (key === 'featured') {
      updateLocalPostJobDetailState('stickies', {
        status: value,
        __typename: 'stickies'
      })
    } else if (key === 'urgent') {
      updateLocalPostJobDetailState('highlighted', value)
    } else {
      updateLocalPostJobDetailState(key, value)
    }
  }

  const handleExternalURLChange = URL => {
    updateLocalPostJobDetailState('externalApplyUrl', URL)
  }
  if (postJobDetail.loading) {
    return null
  }
  if (jobId) {
    return (
      <Query query={GET_JOB_BY_ID} variables={{ jobId }}>
        {({ loading, data: { getJob }, client }) => {
          if (loading || !getJob) {
            return <Loading />
          }

          if (getJob && getJob.data) {
            if (!getJob.data.jobTitleId) getJob.data.jobTitleId = 1
            setIsGetJobLoaded(true)
            client.writeData({
              data: {
                postJobDetail: getJob.data
              }
            })
            const companyId = getJob.data.companyId
            return (
              <Query
                query={GET_RECRUITER_SUBSCRIPTION_PLAN_DETAIL}
                variables={{ userId }}
              >
                {({ loading, data: { userSubscriptionPlan } }) => {
                  if (loading) return <Loading />
                  return (
                    <Query
                      query={GET_COMPANY_WORKING_ADDRESS_LIST}
                      variables={{ companyId }}
                    >
                      {({ loading, data: { getCompanyWorkingAddresses } }) => {
                        if (loading) return <Loading />
                        setWorkAddressData(getCompanyWorkingAddresses.data)
                        return (
                          <PostJobContainer
                            recruiterSubscriptionPlanDetail={
                              userSubscriptionPlan.data
                            }
                            workAddresses={getCompanyWorkingAddresses.data}
                            handleChange={handleJobFieldChange}
                            handleTextEditorChange={handleTextEditorChange}
                            isInvalid={false}
                            updateLocalPostJobDetailState={
                              updateLocalPostJobDetailState
                            }
                            handleDynamicInputFieldChange={
                              handleDynamicInputFieldChange
                            }
                            postJobDetail={postJobDetail.postJobDetail}
                            handleCheckBoxChange={handleBoostJobCheckBoxChange}
                            handleJobBenefitsFieldChange={
                              handleJobBenefitsFieldChange
                            }
                            handleExternalURLChange={handleExternalURLChange}
                            postNewJob={postNewJob}
                            defaultWorkAddress={defaultWorkAddress}
                            options={options}
                            companyId={Number(companyId)}
                          />
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            )
          }
        }}
      </Query>
    )
  } else {
    return (
      <Query
        query={GET_RECRUITER_SUBSCRIPTION_PLAN_DETAIL}
        variables={{ userId }}
      >
        {({ loading, data: { userSubscriptionPlan } }) => {
          if (loading) return <Loading />
          return (
            <Query
              query={GET_COMPANY_WORKING_ADDRESS_LIST}
              variables={{ companyId: companyIdParam }}
            >
              {({ loading, data }) => {
                if (loading) return <Loading />
                setWorkAddressData(data.getCompanyWorkingAddresses.data)
                return (
                  <PostJobContainer
                    recruiterSubscriptionPlanDetail={userSubscriptionPlan.data}
                    workAddresses={
                      data ? data.getCompanyWorkingAddresses.data : []
                    }
                    handleChange={handleJobFieldChange}
                    handleTextEditorChange={handleTextEditorChange}
                    isInvalid={false}
                    updateLocalPostJobDetailState={
                      updateLocalPostJobDetailState
                    }
                    handleDynamicInputFieldChange={
                      handleDynamicInputFieldChange
                    }
                    postJobDetail={
                      postJobDetail ? postJobDetail.postJobDetail : {}
                    }
                    handleCheckBoxChange={handleBoostJobCheckBoxChange}
                    handleJobBenefitsFieldChange={handleJobBenefitsFieldChange}
                    handleExternalURLChange={handleExternalURLChange}
                    postNewJob={postNewJob}
                    options={options}
                    companyId={Number(companyIdParam)}
                  />
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

PostJob.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  updateLocalPostJobDetails: PropTypes.func.isRequired,
  updateLocalPostJobCategories: PropTypes.func.isRequired,
  updateLocalPostJobBenefits: PropTypes.func.isRequired,
  updateLocalPostJobScreeningQuestions: PropTypes.func.isRequired,
  updateLocalPostJobForwardingEmails: PropTypes.func.isRequired,
  addLocalPostJobDynamicInputField: PropTypes.func.isRequired,
  deleteLocalPostJobDynamicInputField: PropTypes.func.isRequired,
  postJobDetail: PropTypes.object.isRequired,
  postNewJob: PropTypes.func.isRequired
}

export default compose(
  graphql(UPDATE_LOCAL_POST_JOB_DETAILS, { name: 'updateLocalPostJobDetails' }),
  graphql(UPDATE_LOCAL_POST_JOB_CATEGORIES, {
    name: 'updateLocalPostJobCategories'
  }),
  graphql(UPDATE_LOCAL_POST_JOB_BENEFITS, {
    name: 'updateLocalPostJobBenefits'
  }),
  graphql(UPDATE_LOCAL_POST_JOB_SCREENING_QUESTIONS, {
    name: 'updateLocalPostJobScreeningQuestions'
  }),
  graphql(UPDATE_LOCAL_POST_JOB_FORWARDING_EMAILS, {
    name: 'updateLocalPostJobForwardingEmails'
  }),
  graphql(ADD_LOCAL_POST_JOB_DYNAMIC_INPUT_FIELD, {
    name: 'addLocalPostJobDynamicInputField'
  }),
  graphql(DELETE_LOCAL_POST_JOB_DYNAMIC_INPUT_FIELD, {
    name: 'deleteLocalPostJobDynamicInputField'
  }),
  graphql(GET_LOCAL_POST_JOB_DETAILS, { name: 'postJobDetail' }),
  graphql(POST_JOB, { name: 'postNewJob' })
  // graphql(POST_JOB, { name: 'postJobDetails' }),
)(withRouter(PostJob))
