import gql from 'graphql-tag'

/*====== Fragments =====*/
const jobFragment = gql`
  fragment jobFields on JobType {
    id
    workArrangementId
    currencyId
    recruiterFirstName
    recruiterLastName
    recruiterId
    jobTitle
    jobTitleRef
    companyName
    companyId
    isCompanyVerified
    statusKey
    createdAt
    dailySalaryRangeFrom
    dailySalaryRangeTo
    hourlySalaryRangeFrom
    hourlySalaryRangeTo
  }
`
const jobDetailFragment = gql`
  fragment jobDetailFields on JobType {
    id
    recruiterFirstName
    recruiterLastName
    recruiterId
    jobTitle
    jobTitleRef
    relatedJobs
    jobTitleId
    functionJobTitle
    jobCategories {
      id
      categoryId
      category {
        id
        value
      }
    }
    jobType
    xpLvl
    degree
    degreeKey
    statusKey
    rejectedReason
    changeRequiredReason
    updatedAt
    createdAt
    currencyId
    salaryRangeFrom
    salaryRangeTo
    address
    floorUnit
    latitude
    longitude
    jobCountry
    jobCountryKey
    countryId

    jobLocationKey
    jobLocationId
    highlighted
    externalApplyUrl
    highlightedExpiredAt
    stickies {
      status
    }
    expireDays
    companyName
    jobDescription
    jobDescriptionHtml
    jobRequirements
    jobRequirementsHtml
    jobSkills
    xpLvlKey
    jobTypeKey
    jobTypeId
    externalApplyUrl
    expiredAt
    companyId
    deletedAt
    refreshedAt
    isSalaryHidden
    publishedAt
    screeningMessage
    screeningQuestions {
      id
      question
    }
    resumeForwardingEmails {
      id
      email
    }
    jobBenefits {
      benefitId
    }
    jdUrl
    isCompanyVerified
    lastEditRecruiterId
    lastEditRecruiterFullname
    workArrangementId
    remoteCountryIds
    isRemoteWorldwide
    languageIds
    timezoneIds
    salaryTypeId
    dailySalaryRangeFrom
    dailySalaryRangeTo
    hourlySalaryRangeFrom
    hourlySalaryRangeTo
    annuallySalaryRangeFrom
    annuallySalaryRangeTo
    isAllLocation
  }
`

/*====== Query ======*/
export const GET_ALL_JOBS = gql`
  query getAllJobs(
    $page: Int
    $query: String
    $date: JobDateTimeRange
    $statusKey: String
    $countryId: Int
    $workArrangementIds: String
  ) {
    allJobs(
      size: 15
      page: $page
      queryData: $query
      date: $date
      statusKey: $statusKey
      countryId: $countryId
      workArrangementIds: $workArrangementIds
    ) {
      data {
        jobs {
          ...jobFields
        }
        totalPages
        currentPage
        totalCount
      }
    }
  }
  ${jobFragment}
`

export const GET_JOB_BY_ID = gql`
  query getJob($jobId: Int!) {
    getJob(jobId: $jobId) {
      data {
        ...jobDetailFields
      }
    }
  }
  ${jobDetailFragment}
`

export const GET_JOB_STAT_BY_ID = gql`
  query getJobStat($jobId: Int!) {
    getJobStat(jobId: $jobId) {
      data {
        totalNew
        totalUnderReview
        totalShortlisted
        totalInterviewed
        totalHired
        totalNotSuitable
        totalNoShow
      }
    }
  }
`

export const GET_ROBO_HEADHUNT_INFORMATION = gql`
  query roboHeadhuntInformation($jobId: Int!) {
    roboHeadhuntInformation(jobId: $jobId) {
      data {
        rhActivationStatus
        totalShortlisted
      }
    }
  }
`

/*====== Local State ======*/
/* Job Detail */
export const GET_LOCAL_JOB_DETAILS = gql`
  {
    jobDetail @client {
      ...jobDetailFields
    }
  }
  ${jobDetailFragment}
`

/*====== Local State ======*/
/* Job Detail */
export const GET_LOCAL_POST_JOB_DETAILS = gql`
  {
    postJobDetail @client {
      id
      jobTitle
      jobTitleRef
      jobTitleId
      relatedJobs
      jobTypeKey
      xpLvlKey
      degreeKey
      currencyId
      salaryRangeFrom
      jobLocationKey
      salaryRangeTo
      jobCountryKey
      floorUnit
      address
      latitude
      longitude
      jobDescriptionHtml
      jobDescription
      jobRequirementsHtml
      jobRequirements
      screeningMessage
      screeningQuestions {
        id
        question
      }
      resumeForwardingEmails {
        id
        email
      }
      jobSkills
      jobCategories {
        category {
          id
          value
        }
      }
      jobBenefits {
        benefitId
      }
      jdUrl
      salaryTypeId
      dailySalaryRangeFrom
      dailySalaryRangeTo
      hourlySalaryRangeFrom
      hourlySalaryRangeTo
    }
  }
`
